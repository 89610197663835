import React, { useEffect } from "react";
import { IntlShape } from "react-intl";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";
import { setInitialRequestorConfig } from "shared/lib/requestorFunctions";
// @ts-ignore
import Logger from "shared/services/Logger";

import LoginForm from "@components/Login/Form";
import pageWithIntl from "@components/PageWithIntl";
import StandardLayout from "@components/UI/Layouts/StandardLayout";
import PageSeo from "@components/Utils/PageSeo";

import { pageDataLayer } from "@lib/dataLayers";

interface LoginProps {
  registrationEnabled: boolean;
  intl: IntlShape;
  cbtEnabled: boolean;
  namespaceSwitchingEnabled: boolean;
}

function Login({ intl }: LoginProps) {
  const { user } = useSelector((state: AppState) => {
    return { user: state.user };
  });

  useEffect(() => {
    pageDataLayer("other", user);
  }, [user]);
  return (
    <>
      <PageSeo
        title={{
          defaultTitle: intl.formatMessage({ id: "Login.title" }),
        }}
      />
      <LoginForm />
    </>
  );
}

function LoginLayout(page) {
  return (
    <StandardLayout
      route={page?.props?.router?.state?.route}
      showCondensedHeaderLogoStripe
      hideSearchForm
      hideFooter
    >
      {page}
    </StandardLayout>
  );
}

Login.getLayout = LoginLayout;

Login.getInitialProps = async (initialProps) => {
  try {
    const { domain } = await setInitialRequestorConfig(initialProps);
    return { domain };
  } catch (e) {
    Logger.error(`Error Initial index: ${JSON.stringify(e)}`);
    return {};
  }
};

export default pageWithIntl(Login);
